var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.$emit('login-user'))}}},[_c('validation-provider',{attrs:{"rules":"required","name":"email/username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"placeholder":"Email/Username","icon":"user"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"password-reveal":"","type":"password","placeholder":"Password","icon":"key"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('b-field',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('b-checkbox',[_vm._v(" Remember Me ")])],1)]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('router-link',{attrs:{"to":"/forgot-password"}},[_vm._v(" Forgot Password? ")])],1)])])]),_c('b-field',[_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"tag":"input","native-type":"submit","disabled":invalid,"value":"sign in","type":"is-info","expanded":""}},[_vm._v(" sign in ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }