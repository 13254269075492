<template>
  <validation-observer
    ref="loginForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form @submit.prevent="handleSubmit($emit('login-user'))">
      <validation-provider
        rules="required"
        name="email/username"
        v-slot="{ errors, valid }"
      >
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            v-model="username"
            placeholder="Email/Username"
            icon="user"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        rules="required"
        name="password"
        v-slot="{ errors, valid }"
      >
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            password-reveal
            v-model="password"
            type="password"
            placeholder="Password"
            icon="key"
          />
        </b-field>
      </validation-provider>

      <b-field>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <b-checkbox>
                Remember Me
              </b-checkbox>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <router-link to="/forgot-password">
                Forgot Password?
              </router-link>
            </div>
          </div>
        </div>
      </b-field>

      <b-field>
        <p class="control">
          <b-button
            tag="input"
            native-type="submit"
            :disabled="invalid"
            value="sign in"
            type="is-info"
            expanded
          >
            sign in
          </b-button>
        </p>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'LoginForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    username: {
      get() {
        return this.$store.state.Authentication.credentials.username;
      },

      set(value) {
        return this.$store.commit('Authentication/SET_CREDENTIALS_USERNAME', value);
      },
    },

    password: {
      get() {
        return this.$store.state.Authentication.credentials.password;
      },

      set(value) {
        return this.$store.commit('Authentication/SET_CREDENTIALS_PASSWORD', value);
      },
    },
  },

};
</script>

<style lang="css" scoped></style>
