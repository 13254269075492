<template>
  <authentication-layout>
    <template #content>
      <div class="card">
        <!-- error message component -->
        <b-message
          v-model="isInvalid"
          type="is-warning"
          has-icon
        >
          {{ message }}
        </b-message>

        <!-- loading component -->
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="false"
        />

        <login-form @login-user="loginUser" />
      </div>
    </template>
  </authentication-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { AuthenticationLayout } from '@/layouts';
import LoginForm from '@/components/Authentication/LoginForm.vue';

import getConfig from '@/plugins/config';

export default {

  name: 'Login',

  components: {
    AuthenticationLayout,
    LoginForm,
  },

  data: () => ({
    message: '',
    isInvalid: false,
  }),

  computed: mapGetters({
    isLoading: 'Authentication/getLoading',
    credentials: 'Authentication/getCredentials',
  }),

  beforeDestory() {
    this.$store.commit('Authentication/CLEAR_CREDENTIALS');
  },

  methods: {
    async loginUser() {
      const VUE_APP_GRANT_TYPE = getConfig('VUE_APP_GRANT_TYPE');
      const payload = new FormData();
      payload.append('grant_type', VUE_APP_GRANT_TYPE);
      payload.append('username', this.credentials.username);
      payload.append('password', this.credentials.password);
      try {
        this.isInvalid = false;
        const { username } = this.credentials;
        await this.$store.dispatch('Authentication/login', { payload, username });
        this.$buefy.notification.open({
          message: 'Successfully logged in',
          type: 'is-success',
          position: 'is-bottom',
        });
        return this.$router.push('/organizations');
      } catch (error) {
        this.message = 'Invalid username/password';
        this.isInvalid = true;
        return this.$buefy.notification.open({
          message: 'Sorry, wrong access credentials',
          type: 'is-warning',
          position: 'is-bottom',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
